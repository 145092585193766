@layer tailwind {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

/* Radix tooltip content to have a z-index more than the mantine modal */
[data-radix-popper-content-wrapper] {
  z-index: 300 !important;
}
